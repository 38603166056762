import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import feedback from "@iconify/icons-ic/comment";
import gifCard from "@iconify/icons-ic/card-giftcard";
import label from "@iconify/icons-ic/label";
import notificationIcon from "@iconify/icons-ic/notifications";
import settings from "@iconify/icons-ic/settings";
import privacyIcon from "@iconify/icons-ic/round-privacy-tip";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "User",
    path: "/dashboard/user",
    icon: getIcon(peopleFill),
  },
  {
    title: "Orders",
    path: "/dashboard/orders",
    icon: getIcon(fileTextFill),
  },
  {
    title: "Product",
    path: "/dashboard/products",
    icon: getIcon(shoppingBagFill),
  },
  // {
  //   title: "Add new product",
  //   path: "/dashboard/new-product",
  //   icon: getIcon(plus),
  // },
  {
    title: "Feedback & reviews",
    path: "/dashboard/feedback-review",
    icon: getIcon(feedback),
  },

  {
    title: "Coupons",
    path: "/dashboard/coupons",
    icon: getIcon(gifCard),
  },
  {
    title: "Status",
    path: "/dashboard/status",
    icon: getIcon(label),
  },
  {
    title: "Notifications",
    path: "/dashboard/notification",
    icon: getIcon(notificationIcon),
  },
  {
    title: "Settings",
    path: "/dashboard/settings",
    icon: getIcon(settings),
  },
  {
    title: "Policies",
    path: "/dashboard/privacy-policy",
    icon: getIcon(privacyIcon),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
