import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { useObserver } from "mobx-react";
import { dateFormat } from "../utils/common";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import { useStores } from "src/state_management/store";
import OrderDetailsModal from "src/reusable/orders_modal";
import { toJS } from "mobx";
import { MdRefresh } from "react-icons/md";
import MoreMenu from "src/components/reusable/more_menu";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "game", label: "Game", alignRight: false },
  // { id: "laps", label: "laps", alignRight: false },
  { id: "persons", label: "Persons", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
  { id: "paymentStatus", label: "Payment", alignRight: false },
  { id: "schedule", label: "Slot", alignRight: false },
  { id: "createdAt", label: "Created", alignRight: false },
  { id: "isVerified", label: "Total", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log("applySortFilter", array, comparator, query);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const fil = filter(
      array,
      (_user) =>
        _user.uDetails?.name.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _user.orderId.toString().indexOf(query) !== -1 ||
        _user.uDetails?.mobile.toString().indexOf(query) !== -1
    );
    return fil;
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Orders() {
  const { OrdersStore, CommonStore } = useStores();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    if (OrdersStore.ordersList.length > 0) return;
    OrdersStore.fetchOrdersFromBD();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    const divi = OrdersStore.ordersList.length / rowsPerPage;
    const page = Math.ceil(divi);
    if (newPage == page - 1) {
      console.log("final page");
      //fetch more data here
      OrdersStore.fetchOrdersFromBD(true);
    }
    console.log(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    OrdersStore.ordersList,
    getComparator(order, orderBy),
    filterName
  );
  console.log(toJS(filteredUsers));
  console.log(toJS(filteredUsers));
  const isUserNotFound = filteredUsers.length === 0;

  const getPersons = (orders) => {
    let count = 0;
    orders.forEach((order) => {
      count += order.persons;
    });
    return count;
  };

  const getOrderList = () => {
    if (isUserNotFound) {
      return OrdersStore.ordersList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return filteredUsers?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
  };

  const getLabelColor = (status) => {
    switch (status) {
      case "Payment Done":
        return "success";
        break;

      case "Payment Pending":
        return "warning";
        break;

      case "Payment failed":
        return "error";
        break;
      case "Payment Cancelled":
        return "error";
        break;
      case "Payment initiated":
        return "info";
        break;

      case "Payment Processing":
        return "warning";

      default:
        return "info";
        break;
    }
  };

  return useObserver(() => (
    <Page title="Orders">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              OrdersStore.fetchOrdersFromBD(false, true);
            }}
            startIcon={<MdRefresh />}
          >
            Reload
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            searchLabel="Search order by order id, name or mobile"
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={OrdersStore.ordersList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {getOrderList().map((order) => {
                    const {
                      _id,
                      status,
                      avatarUrl,
                      isVerifiedBy,
                      uDetails,
                      orders,
                      totalPrice,
                      isGameCompleted,
                      couponsDetails,
                      paymentStatus,
                      createdAt,
                    } = order;
                    const isItemSelected =
                      selected.indexOf(uDetails?.name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        // selected={isItemSelected}
                        // aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            // checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, uDetails?.name)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={uDetails?.name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {uDetails?.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="pointer"
                          onClick={() => {
                            setOpenModal(true);
                            setOrderDetails(toJS(order));
                          }}
                        >
                          {orders[0]?.game?.name}
                        </TableCell>
                        <TableCell align="right">
                          {getPersons(orders)}
                        </TableCell>
                        <TableCell align="left">{status.status}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            disabled
                            color={
                              isGameCompleted
                                ? "default"
                                : isVerifiedBy
                                ? "success"
                                : getLabelColor(paymentStatus.status)
                            }
                          >
                            {isGameCompleted
                              ? "Check Out"
                              : isVerifiedBy
                              ? "Check In"
                              : sentenceCase(paymentStatus.status)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {dateFormat(orders[0]?.schedule, "ddd  hh:mm a")}
                        </TableCell>
                        <TableCell align="left">
                          {dateFormat(createdAt, "ddd  hh:mm a")}
                        </TableCell>
                        <TableCell align="left">
                          <b>₹ {totalPrice}</b>
                        </TableCell>

                        <TableCell align="right">
                          <MoreMenu
                            labels={["view", "check in", "check out"]}
                            onClick1={() => {
                              setOpenModal(true);
                              setOrderDetails(toJS(order));
                            }}
                            onClick2={() => {
                              OrdersStore.editOrderLocalStore(order._id, {
                                isVerifiedBy: CommonStore?.adminUser?._id,
                                isGameCompleted: false,
                              });
                            }}
                            onClick3={() => {
                              //update the game as completed
                              OrdersStore.editOrderLocalStore(order._id, {
                                isGameCompleted: true,
                                isCompletedBy: CommonStore?.adminUser?._id,
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[2, 5, 10, 15, 25]}
            component="div"
            count={OrdersStore.ordersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {openModal && (
          <OrderDetailsModal
            open={openModal}
            data={orderDetails}
            onClose={() => {
              setOpenModal(false);
            }}
          />
        )}
      </Container>
    </Page>
  ));
}
