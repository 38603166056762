import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { MdCalculate, MdCheck, MdCheckCircleOutline } from "react-icons/md";
import BasicModal from "./basic_modal";
import { useStores } from "src/state_management/store";
import { Box } from "@mui/system";
import { dateFormat } from "src/utils/common";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function OrderDetailsModal(props) {
  const { OrdersStore } = useStores();
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const [razorOrder, setRazorOrder] = React.useState({});
  const razorDetailsRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (props.open) {
      handleClickOpen();
      setOrder(props.data);
      console.log(props.data);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    props?.onClose();
  };

  const razorPayOrderDetails = async (orderId) => {
    let response = await OrdersStore.getRazorPayOrder(orderId);
    if (response != null) {
      setRazorOrder(response);
      setTimeout(() => {
        //scroll down to razor details
        razorDetailsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {open == true && (
        <BasicModal
          width="md"
          modalOpen={true}
          onClose={props?.onClose}
          title="Order deails"
          children={mainComponent}
        />
      )}
    </div>
  );

  function mainComponent() {
    return (
      // <DialogContent dividers>
      <div style={{ width: "1000px" }}>
        <Typography gutterBottom>Order ID: {props?.data?.orderId}</Typography>
        <Typography gutterBottom>
          <h3>
            <b> Order Details</b>
          </h3>
          <br></br>

          {order?.orders?.map((order) => (
            <>
              <b>Game</b>: {order?.game?.name},<br></br> <b>Lap</b> :{" "}
              {order?.laps?.name},<br></br> <b>Price</b> :{order?.laps?.price},{" "}
              <br></br>
              <b>Persons</b>: {order?.persons}, <br></br>
              <b>Schedule</b>: {order?.time}
              {" at "}
              {dateFormat(order?.schedule, "DD MMMM")}
              <br></br>
              <br></br>
            </>
          ))}
        </Typography>
        <b>Total Price - ₹ {order?.totalPrice}</b>
        <br></br>
        <p>
          {"Explanation -> "} ₹{" "}
          <b>{order?.allGamesPrice ?? order?.totalPrice}</b>
          <small>(Order value)</small>- <b>{order?.discount ?? "0"}</b>
          <small>(discount)</small> - <b>{order?.coins ?? "0"}</b>
          <small>(coins)</small> = ₹ <b>{order?.totalPrice}</b>
        </p>
        <Typography>
          <br></br>
          <b>Status</b>: {order?.status?.status}
          <br></br>
          <b>Payment Type</b>: {order?.paymentType?.status}
          <br></br>
          <b>Payment Status</b>: {order?.paymentStatus?.status}
          <br></br>
          <b>Payment Verified</b>: {order?.isPaymentVerified ? "Yes" : "No"}
          <br></br>
          <b>Payment Gateway</b>: {order?.paymentGateway?.status}
          <br></br>
          <b>Gateway payment status</b>: {order?.gateWayStatus}
          <br></br>
          <b>Gateway orderId</b>: {order?.gateWayOrderId ?? "N/A"}
          <br></br>
          <b>isPayment signature verified</b> :{" "}
          {order?.paymentSignatureVerified ? "Yes" : "No"}
          <br></br>
          {order?.isCouponApplied && <b> Coupons Applied</b>}
          <br></br>
          {order?.couponsDetails && (
            <>
              <h3>Coupon Details</h3>
              <br></br>
              <b>Coupon Code</b>: {order?.couponsDetails?.code}
              <br></br>
              <b>Coupon Discount</b>:{" "}
              {order?.couponsDetails?.discountType == "percentage"
                ? order?.couponsDetails?.discount + "%"
                : order?.couponsDetails?.discount}
              <br></br>
              <b>Max Discount</b>: {order?.couponsDetails?.maxDiscount}
              <br></br>
              <b>Coupon Type</b>: {order?.couponsDetails?.couponType}
              <br></br>
              <b>Coupon Expiry Date</b>:{" "}
              {dateFormat(order?.couponsDetails?.endDate, "DD MMMM YYYY")}
              <br></br>
            </>
          )}
        </Typography>
        <br></br>

        <Typography>
          <h3>
            <b>User details</b>
          </h3>
          <b>name</b>: {order?.uDetails?.name}
          <br></br>
          <b>mobile</b>: {order?.uDetails?.mobile}
          <br></br>
          <b> email</b>: {order?.uDetails?.eMail}
          <br></br>
          <b> userId</b>: {order?.uDetails?.uId}
        </Typography>
        {/* <DialogActions> */}
        {/* <Button startIcon={<MdCalculate />} variant="outlined">
            Calculate price
          </Button> */}
        <Box sx={{ p: 2 }}>
          <Button
            startIcon={<MdCheckCircleOutline />}
            variant="outlined"
            onClick={() => {
              razorPayOrderDetails(order?.gateWayOrderId);
            }}
          >
            Check payment in Razorpay
          </Button>
        </Box>
        {/* </DialogActions> */}

        {Object.keys(razorOrder).map((keyName, i) => (
          <>
            <div>
              {i == 0 && (
                <>
                  <h3>
                    <b>Razorpay Order Details</b>
                  </h3>
                  <br ref={razorDetailsRef}></br>
                </>
              )}
            </div>
            <li className="travelcompany-input" key={i}>
              <span className="input-label">
                {keyName} :{" "}
                {keyName == "amount" ||
                keyName == "amount_paid" ||
                keyName == "amount_due"
                  ? razorOrder[keyName] / 100
                  : // : keyName === "created_at"
                    // ? dateFormat(razorOrder[keyName], "DD MMMM YYYY")
                    razorOrder[keyName]}
              </span>
            </li>
          </>
        ))}
      </div>
      // </DialogContent>
    );
  }
}
