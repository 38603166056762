import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import { Observer } from "mobx-react";
import CouponCard from "./CouponCard";

// ----------------------------------------------------------------------

CouponList.propTypes = {
  coupons: PropTypes.array.isRequired,
};

export default function CouponList({ coupons, selected, ...other }) {
  return (
    <Observer>
      {() => (
        <Grid container spacing={3} {...other}>
          {selected
            ? coupons
                .filter((coupon) => coupon.selectedUser.length > 0)
                .map((coupon, key) => (
                  <Grid key={key} item xs={12} sm={6} md={3}>
                    <div>
                      <CouponCard coupon={coupon} />
                    </div>
                  </Grid>
                ))
            : coupons
                .filter((coupon) => coupon.selectedUser.length === 0)
                .map((coupon, key) => (
                  <Grid key={key} item xs={12} sm={6} md={3}>
                    <div>
                      <CouponCard coupon={coupon} />
                    </div>
                  </Grid>
                ))}
        </Grid>
      )}
    </Observer>
  );
}
