import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";

// material
import { Menu, MenuItem, IconButton, ListItemText } from "@mui/material";

// ----------------------------------------------------------------------

export default function MoreMenu({
  labels,
  onClick1,
  onClick2,
  onClick3,
  onClick4,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const getMenu = () => {
    return (
      <>
        {labels.map((label, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                setIsOpen(false);
                if (index === 0) {
                  onClick1();
                } else if (index === 1) {
                  onClick2();
                } else if (index === 2) {
                  onClick3();
                } else if (index === 3) {
                  onClick4();
                }
              }}
            >
              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </>
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 220, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {getMenu()}
      </Menu>
    </>
  );
}
