import { makeAutoObservable, toJS } from "mobx";
import {
  apiDelete,
  apiGet,
  apiPostPut,
} from "../../resources/api_calls/api_methods";
import apiUrl from "../../resources/api_calls/api_urls";
import CommonStore from "./common_store";

class CouponStore {
  showActiveCoupon = false;
  listCoupons = [];
  listUsers = [];
  loading = false;
  activeCouponsList = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(state) {
    this.loading = state ?? false;
  }

  async createNewCoupon(body) {
    const result = await apiPostPut(body, apiUrl.newCoupon, "POST");
    if (result.status === 200) {
      console.log("Coupon created");
      const tempListCoupon = [...this.listCoupons, result?.body?.data];
      console.log(result);
      console.log(tempListCoupon);
      this.listCoupons = tempListCoupon;
      return true;
    } else {
      alert("Error + " + result.status + " " + result.body.message);
      return false;
    }
  }

  async searchForUser(query) {
    const result = await apiGet(apiUrl.search + `/${query}`);
    if (result.status === 200) {
      console.log(result.body.data);
      const tempListUser = [];
      tempListUser.push(result?.body?.data);
      this.listUsers = tempListUser[0];
      console.log(tempListUser);
      return true;
    } else {
      alert("Error + " + result.status + " " + result.body.message);
      return false;
    }
  }

  addAndFetchedCouponFromAPI = async () => {
    const response = await apiGet(apiUrl.fetchAllCoupons);
    if (response.status === 200) {
      // response.body.map((data) => this.listCoupons.push(data));
    }
    const response2 = await apiGet(apiUrl.fetchInactiveCoupons);
    if (response2.status === 200) {
      response2.body.map((data) => this.listCoupons.push(data));
    }
  };

  fetchCoupons = async () => {
    const response = await apiGet(apiUrl.fetchAllCoupons);
    console.log("respo", response);
  };

  fetchCouponFromDB = async () => {
    console.log("getting coupons from db");
    this.loading = true;
    const resp = await apiGet(apiUrl.listCoupons);

    this.loading = false;
    if (resp.status === 200) {
      this.listCoupons = resp.body?.data;
      console.log(resp.body?.data);
    } else {
      console.log("went wrong", resp.status);
    }

    this.activeCouponsList = this.listCoupons.filter(
      (el) => el.isActive === true
    );
  };

  deleteCoupon = async (pId) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let path = `/coupon/coupons/${pId}`;
    this.loading = true;
    const resp = await apiDelete(path);
    this.loading = false;
    if (resp.status === 200 || resp.status === 204) {
      console.log("deleted");
      this.delete2(pId);
    }
  };

  delete2 = (pId) => {
    let remainingProds = this.listCoupons.filter((user) => user._id != pId);
    console.log(remainingProds);
    this.listCoupons = remainingProds;
  };

  getProdDetById = (pId) => {
    let index = this.listCoupons.findIndex((prod) => prod._id == pId);
    if (index < 0) return "no prod here";
    return this.listCoupons[index];
  };

  editCouponInDB = async (pId, editedData) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let body = {
      ...editedData,
    };
    let path = `/coupon/coupons/${pId}`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "PUT");
    this.loading = false;

    return resp.status;
  };

  editCouponLocalStore = async (pId, editedData) => {
    let dataToBeUpdate = this.getProdDetById(pId);
    const indexTobeEdited = this.listCoupons.indexOf(dataToBeUpdate);
    this.listCoupons[indexTobeEdited] = { ...dataToBeUpdate, ...editedData };
    console.log(toJS(this.listCoupons));

    const updateStatus = await this.editCouponInDB(pId, editedData);

    if (updateStatus !== 200) {
      this.listCoupons[indexTobeEdited] = { ...dataToBeUpdate };
    }
  };

  setActiveCoupon() {
    this.activeCouponsList = this.listCoupons.filter(
      (el) => el.isActive === true
    );
  }

  getActiveCoupon = () => {
    if (this.showActiveCoupon) {
      return this.listCoupons.filter((coupon) => coupon.isActive === true);
    }
  };

  addCouponToDataBase = async (body) => {
    let path = `"/coupon/new-coupons"`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "POST");
    this.loading = false;
    return resp.status;
  };
  addCouponToLocalStore = (body) => {
    // this.listCoupons.push(body)
    const len = this.listCoupons.length;
    this.listCoupons[len] = body;
    const updateStatus = this.addCouponToDataBase(body);

    if (updateStatus !== 200) {
      this.listCoupons.pop();
    }
  };
}

export default CouponStore;
