import React, { useEffect } from "react";
import BasicModal from "./basic_modal";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import { useStores } from "src/state_management/store";
import { uploadFileToFirebaseStorage } from "src/utils/common";

export default function NewProductModal(props) {
  const { CommonStore, ProductStore } = useStores();
  const [isGame, setIsGame] = React.useState(props?.isGame ?? null);
  const [isEdit, setIsEdit] = React.useState(props?.isEdit ?? false);
  const [pickedImage, setPickedImage] = React.useState(null);
  //create a ref to store the textInput DOM element
  const nameRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const typeRef = React.useRef(null);
  const descriptionRef = React.useRef(null);

  const dummyImage =
    "https://toppng.com/uploads/preview/file-upload-image-icon-115632290507ftgixivqp.png";

  const handleChange = (event) => {
    setIsGame(event.target.value);
  };

  const submitGame = async (e) => {
    e.preventDefault();
    if (pickedImage === null && !isEdit) {
      alert("Please upload an image");
      return;
    }
    ProductStore.setLoading(true);
    let image =
      pickedImage == null
        ? [props?.data?.media[0]?.url]
        : await uploadFileToFirebaseStorage([pickedImage]);
    ProductStore.setLoading(false); 
    console.log(image);

    console.log("submitGame");
    console.log(nameRef);
    const body = {
      price: priceRef.current.value,
      description: descriptionRef.current.value,
      name: nameRef.current.value,
      createdBy: CommonStore?.adminUser?._id,
      media: [
        {
          url: image[0],
          type: "image",
        },
      ],
    };

    console.log(body);

    if (isEdit) {
      ProductStore.editProductLocalStore(props?.data?._id, body);
      alert("Game updated successfully");
      props?.onClose();
    } else {
      if ((await ProductStore.createNewGame(body)) === true) {
        alert("Game created successfully");
        props?.onClose();
      }
    }

    // console.log(priceRef.current.value);
    // console.log(typeRef.current.value);
    // console.log(descriptionRef.current.value);
  };
  const getImgage = () => {
    if (isEdit === true && pickedImage === null) {
      return props?.data?.media[0]?.url ?? dummyImage;
    } else {
      return pickedImage == null
        ? dummyImage
        : URL.createObjectURL(pickedImage);
    }
  };
  const submitLap = async (e) => {
    e.preventDefault();
    console.log("submit lap");
    console.log(nameRef.current.value);
    console.log(priceRef.current.value);
    console.log(typeRef.current.value);
    console.log(descriptionRef.current.value);

    const body = {
      price: priceRef.current.value,
      description: descriptionRef.current.value,
      name: nameRef.current.value,
      createdBy: CommonStore?.adminUser?._id,
      type: typeRef.current.value,
    };
    if ((await ProductStore.createNewLap(body)) === true) {
      alert("Lap created successfully");
      props?.onClose();
    }
  };
  const gameForm = () => {
    return (
      <form onSubmit={submitGame}>
        <Box>
          <TextField
            id="name"
            label="Game name"
            inputRef={nameRef}
            required
            defaultValue={isEdit ? props?.data?.name : ""}
          />
          <TextField
            id="price"
            label="Price"
            inputRef={priceRef}
            required
            defaultValue={isEdit ? props?.data?.price : ""}
          />
          <TextField
            id="description"
            label="Description"
            inputRef={descriptionRef}
            minRows="3"
            multiline
            required
            defaultValue={isEdit ? props?.data?.description : ""}
          />
          <input
            type="file"
            id="file"
            hidden
            onChange={(e) => {
              setPickedImage(e.target.files[0]);
              console.log(e.target.files[0]);
            }}
          />
          <img
            className="pointer"
            onClick={() => {
              document.getElementById("file").click();
            }}
            src={getImgage()}
            alt="game"
          />
        </Box>
        {/* submit button */}
        <Button type="submit">{isEdit ? "Update" : "Create"}</Button>
      </form>
    );
  };

  const lapForm = () => {
    return (
      <form onSubmit={submitLap}>
        <Box>
          <TextField id="name" label="Lap name" inputRef={nameRef} required />
          <TextField id="price" label="Price" inputRef={priceRef} required />
          <TextField id="type" label="Type" inputRef={typeRef} required />

          <TextField
            id="description"
            label="Description"
            inputRef={descriptionRef}
            minRows="3"
            multiline
            required
          />
        </Box>
        {/* submit button */}
        <Button type="submit">{isEdit ? "Update" : "Create"}</Button>
      </form>
    );
  };

  const mainComponent = () => {
    return (
      <div>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Please select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isGame}
              label="Please select"
              onChange={handleChange}
            >
              <MenuItem value={true}>New Game</MenuItem>
              <MenuItem value={false}>New Lap</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {isGame ? gameForm() : null}
        {isGame == false ? lapForm() : null}
      </div>
    );
  };

  return (
    <div>
      <BasicModal
        title={"new product"}
        modalOpen={props.open}
        onClose={props.onClose}
        children={mainComponent}
      />
    </div>
  );
}
