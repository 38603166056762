// material
import { Box, Grid, Container, Typography, Button, Stack } from "@mui/material";
import { useEffect } from "react";
import { useStores } from "../state_management/store";
// import TodayRevenue from "../components/_dashboard/app/today_revenue";
import androidFilled from "@iconify/icons-ant-design/control-fill";
import plusFill from "@iconify/icons-ant-design/reload-outline";
import windowsFilled from "@iconify/icons-ant-design/shopping-filled";
import walleteIcon from "@iconify/icons-ant-design/wallet-fill";
import appleFilled from "@iconify/icons-ant-design/user";
import firebase from "../firebase";

import { Icon } from "@iconify/react";
// components
import Page from "../components/Page";
import { AppWeeklySales } from "../components/_dashboard/app";
import { Observer } from "mobx-react";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { UserStore, ProductStore, CommonStore } = useStores();

  useEffect(() => {
    if (UserStore.listUser.length < 1) UserStore.fetchUserFromDB();
    if (
      CommonStore.adminUser === null ||
      Object.keys(CommonStore.adminUser).length === 0
    ) {
      CommonStore.fetchAdminUserFromDB(firebase.auth().currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (ProductStore.listProducts.length < 1) ProductStore.fetchProductFromDB();
    CommonStore.fetchDashBoardDetails();
  }, []);

  console.log(`"welcome here"`);
  return (
    <Observer>
      {() => (
        <Page title="Dashboard">
          <Container maxWidth="xl">
            <Stack
              sx={{ pb: 5 }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h4">
                Hi, Welcome {CommonStore?.adminUser?.name}
              </Typography>
              <Button
                variant="contained"
                to="#"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => CommonStore.fetchDashBoardDetails(true)}
              >
                Reload
              </Button>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWeeklySales
                  tagName="Active Games"
                  count={CommonStore.dashBoardDetails?.games?.active}
                  counts={[
                    CommonStore.dashBoardDetails?.games?.active,
                    CommonStore.dashBoardDetails?.games?.inActive,
                    CommonStore.dashBoardDetails?.games?.total,
                  ]}
                  today="Active"
                  week="Inactive"
                  month="Total"
                  primaryC="lightgreen"
                  icon={androidFilled}
                  secondaryC="#7bc37b"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {/* <AppNewUsers /> */}
                <AppWeeklySales
                  tagName="Today New Users"
                  count={CommonStore.dashBoardDetails?.users?.today}
                  counts={[
                    CommonStore.dashBoardDetails?.users?.today,
                    CommonStore.dashBoardDetails?.users?.week,
                    CommonStore.dashBoardDetails?.users?.month,
                  ]}
                  primaryC="#d0f2ff"
                  icon={appleFilled}
                  secondaryC="#bee3f8"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {/* <AppItemOrders /> */}
                <AppWeeklySales
                  tagName="Today Orders"
                  count={CommonStore.dashBoardDetails?.orders?.today}
                  counts={[
                    CommonStore.dashBoardDetails?.orders?.today,
                    CommonStore.dashBoardDetails?.orders?.week,
                    CommonStore.dashBoardDetails?.orders?.month,
                  ]}
                  primaryC="#fff7cd"
                  icon={windowsFilled}
                  secondaryC="#f3e3ab"
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                {/* <TodayRevenue /> */}
                <AppWeeklySales
                  tagName="Today Revenue"
                  count={"₹ " + CommonStore.dashBoardDetails?.revenue?.today}
                  counts={[
                    "₹ " + CommonStore.dashBoardDetails?.revenue?.today,
                    "₹ " +
                      ~~(CommonStore.dashBoardDetails?.revenue?.week / 1000) +
                      "K",
                    "₹ " +
                      ~~(CommonStore.dashBoardDetails?.revenue?.month / 1000) +
                      "K",
                  ]}
                  primaryC="#56e8e3"
                  icon={walleteIcon}
                  secondaryC="#5bc2be"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {/* <TodayRevenue /> */}
                <AppWeeklySales
                  today="Android"
                  week="iOS"
                  month=""
                  tagName="App download"
                  count={CommonStore.dashBoardDetails?.users?.androidApprox}
                  counts={[
                    CommonStore.dashBoardDetails?.users?.androidApprox,
                    CommonStore.dashBoardDetails?.users?.ios + "+",
                    "",
                  ]}
                  primaryC="#56e8e3"
                  icon={walleteIcon}
                  secondaryC="#5bc2be"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {/* <AppNewUsers /> */}
                <AppWeeklySales
                  tagName="Active users"
                  today="Active users"
                  week="Active users"
                  month="Active users"
                  count={CommonStore.dashBoardDetails?.users?.online}
                  counts={[
                    CommonStore.dashBoardDetails?.users?.online,
                    CommonStore.dashBoardDetails?.users?.online,
                    CommonStore.dashBoardDetails?.users?.online,
                  ]}
                  primaryC="lightgreen"
                  secondaryC="#7bc37b"
                  icon={appleFilled}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}

              {/* required but hidden */}

              {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}

              {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid> */}

              {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

              {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

              {/* same */}
              {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}

              {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
            </Grid>
          </Container>
        </Page>
      )}
    </Observer>
  );
}
