import React from "react";
import BasicModal from "src/reusable/basic_modal";
import { dateFormat, format } from "src/utils/common";

export default function UserModel(props) {
  const mainComponent = () => {
    return (
      <>
        <img alt="" src={props.details.pic} className="avatar-image" />
        <br />
        <ul className="profile-list">
          <li>
            <b>Name</b> : {props.details.name}
          </li>
          <li>
            <b>Unique ID</b> : {props.details.uId}
          </li>
          <li>
            <b>Mobile</b> : {props.details.mobile}
          </li>
          <li>
            <b>Email</b> : {props.details.eMail}
          </li>
          <li>
            <b>Coin available</b> : {props.details.coins}
          </li>
          <li>
            <b>Refferal code</b> : {props.details.refferalCode}
          </li>
          <li>
            <b>Join@</b> : {dateFormat(props?.details?.createdAt, format[1])}
          </li>
          <li>
            <b>Last Modified@</b> :
            {dateFormat(props?.details?.lastModified, format[0])}
          </li>
          <li>
            <b>Status</b> : {props.details.userState}
          </li>
          {/* <li>
            <b>Last login</b> : {props.details.lastLogin}
          </li> */}
        </ul>
      </>
    );
  };

  return (
    <>
      <BasicModal
        width="md"
        onClose={props?.onClose}
        modalOpen={props?.open}
        title="Order deails"
        children={mainComponent}
      />
    </>
  );
}
