module.exports = Object.freeze({
  baseUrl: "https://web-production-69d4.up.railway.app/api",
  localUrl: "http://localhost:4000/api",
  isLocalServer: false,
  fetchAllProducts: "/game/all-games?isActive=all",
  fetchAllCoupons: "/coupon/all-coupons?isActive=all",
  fetchInactiveProducts: "/game/all-games?isActive=all",
  fetchInactiveCoupons: "/coupon/all-coupons?isActive=all",
  listUsers: "/user/all-users",
  testRides: "/test-ride/all-test-rides",
  listProducts: "/game/all-games?isActive=all",
  listCoupons: "/coupon/all-coupons?isActive=all",
  listOrders: "/order/all-orders",
  login: "/public/user/login",
  search: "/user/search-user",
  lapsList: "/lap/all-laps?isActive=all",
  newGame: "/game/new-game",
  newCoupon: "/coupon/new-coupon",
  newLap: "/lap/new-lap",
  razorPayOrder: "/gate-way/get-order",
  dashboard_details: "/order/dash-board-details",
  notificationByTopic: "/notification/notificationByTopic",
});
