import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Stack,
  Container,
  Typography,
  Card,
  Button,
  CardContent,
  CardMedia,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import Page from "../components/Page";

import { MdAdd } from "react-icons/md";
import { MdRefresh } from "react-icons/md";

import NewCouponModal from "src/reusable/new_coupon_modal";
import CouponList from "src/components/_dashboard/coupons/CouponList";
import { useObserver } from "mobx-react";
import { useStores } from "src/state_management/store";
import CouponFilterSidebar from "src/components/_dashboard/coupons/CouponFilterSidebar";

export default function CouponsPage() {
  const [openAddCoupon, setOpenAddCoupon] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selected, setSelected] = useState(false);

  const formik = useFormik({
    initialValues: {
      users: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const { CouponStore } = useStores();

  // Methods for more menu Option
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (CouponStore.listCoupons.length > 0) return;
    console.log("listCoupons");
    CouponStore.fetchCouponFromDB();
  }, []);

  return useObserver(() => (
    <Page title="Coupons">
      <Container>
        <Typography variant="h4" gutterBottom>
          Coupons
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                CouponStore.fetchCouponFromDB();
              }}
              startIcon={<MdRefresh />}
            >
              Reload
            </Button>
            <CouponFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              setSelected={setSelected}
              selected={selected}
            />
            <Button
              color="inherit"
              disableRipple
              // onClick={handleOpen}
              onClick={() => {
                setOpenAddCoupon(true);
              }}
              endIcon={<MdAdd />}
            >
              Add
            </Button>
          </Stack>
        </Stack>

        {openAddCoupon && (
          <NewCouponModal
            open={openAddCoupon}
            onClose={() => {
              setOpenAddCoupon(false);
            }}
            title="Add New Coupon"
          />
        )}
        <CouponList selected={selected} coupons={CouponStore.listCoupons} />
      </Container>
    </Page>
  ));
}
