import { makeAutoObservable, toJS } from "mobx";
import {
  apiDelete,
  apiGet,
  apiPostPut,
} from "../../resources/api_calls/api_methods";
import apiUrl from "../../resources/api_calls/api_urls";
import CommonStore from "./common_store";

class ProductStore {
  price = 1;
  showActiveProduct = false;
  listProducts = [];
  loading = false;
  activeProductsList = [];
  lapsList = [];
  currentLaps = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(state) {
    this.loading = state ?? false;
  }

  async createNewGame(body) {
    const result = await apiPostPut(body, apiUrl.newGame, "POST");
    if (result.status === 200) {
      console.log("Game created");
      const tempListProduct = [...this.listProducts, result?.body?.data];
      console.log(result);
      console.log(tempListProduct);
      this.listProducts = tempListProduct;
      return true;
    } else {
      alert("Error + " + result.status + " " + result.body.message);
      return false;
    }
  }

  setPrice() {
    this.price += 1;
    console.log(this.price);
  }

  addAndFetchedProductFromAPI = async () => {
    const response = await apiGet(apiUrl.fetchAllProducts);
    if (response.status === 200) {
      // response.body.map((data) => this.listProducts.push(data));
    }
    const response2 = await apiGet(apiUrl.fetchInactiveProducts);
    if (response2.status === 200) {
      response2.body.map((data) => this.listProducts.push(data));
    }
  };

  fetchProducts = async () => {
    const response = await apiGet(apiUrl.fetchAllProducts);
    console.log("respo", response);
    this.price += response.body.length;
  };

  fetchProductFromDB = async () => {
    console.log("getting games from db");
    this.loading = true;
    const resp = await apiGet(apiUrl.listProducts);

    this.loading = false;
    if (resp.status === 200) {
      this.listProducts = resp.body?.data;
      console.log(resp.body?.data);
    } else {
      console.log("went wroing", resp.status);
    }

    this.activeProductsList = this.listProducts.filter(
      (el) => el.isActive === true
    );
  };

  deleteProduct = async (pId) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let path = `/game/games/${pId}`;
    this.loading = true;
    const resp = await apiDelete(path);
    this.loading = false;
    if (resp.status === 200 || resp.status === 204) {
      console.log("deleted");
      this.delete2(pId);
    }
  };

  delete2 = (pId) => {
    let remainingProds = this.listProducts.filter((user) => user._id != pId);
    console.log(remainingProds);
    this.listProducts = remainingProds;
  };

  getProdDetById = (pId) => {
    let index = this.listProducts.findIndex((prod) => prod._id == pId);
    if (index < 0) return "no prod here";
    return this.listProducts[index];
  };

  editProductInDB = async (pId, editedData) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let body = {
      ...editedData,
    };
    let path = `/game/games/${pId}`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "PUT");
    this.loading = false;

    return resp.status;
  };

  editProductLocalStore = async (pId, editedData) => {
    let dataToBeUpdate = this.getProdDetById(pId);
    const indexTobeEdited = this.listProducts.indexOf(dataToBeUpdate);
    this.listProducts[indexTobeEdited] = { ...dataToBeUpdate, ...editedData };
    console.log(toJS(this.listProducts));

    const updateStatus = await this.editProductInDB(pId, editedData);

    if (updateStatus !== 200) {
      this.listProducts[indexTobeEdited] = { ...dataToBeUpdate };
    }
  };

  setActiveProduct() {
    this.activeProductsList = this.listProducts.filter(
      (el) => el.isActive === true
    );
  }

  getActiveProduct = () => {
    if (this.showActiveProduct) {
      return this.listProducts.filter((product) => product.isActive === true);
    }
  };

  addProductToDataBase = async (body) => {
    let path = `"/product/new-products"`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "POST");
    this.loading = false;
    return resp.status;
  };
  addProductToLocalStore = (body) => {
    // this.listProducts.push(body)
    const len = this.listProducts.length;
    this.listProducts[len] = body;
    const updateStatus = this.addProductToDataBase(body);

    if (updateStatus !== 200) {
      this.listProducts.pop();
    }
  };

  /* ------------------------------ LAPS METHODS ------------------------------ */

  createNewLap = async (body) => {
    const result = await apiPostPut(body, apiUrl.newLap, "POST");
    if (result.status === 200) {
      console.log("Lap created");
      const tempListLaps = [...this.lapsList, result?.body?.data];
      console.log(result);
      console.log(tempListLaps);
      this.lapsList = tempListLaps;
      return true;
    } else {
      alert("Error + " + result.status + " " + result.body.message);
      return false;
    }
  };

  editLapsInDB = async (pId, editedData) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let body = {
      ...editedData,
    };
    let path = `/lap/laps/${pId}`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "PUT");
    this.loading = false;

    if (resp.status !== 200) {
      alert("Error + " + resp.status + " " + resp.body.message);
    }

    return resp.status;
  };

  fetchLapsFromDB = async () => {
    console.log("getting laps from db");
    this.loading = true;
    const resp = await apiGet(apiUrl.lapsList);

    this.loading = false;
    if (resp.status === 200) {
      this.lapsList = resp.body?.data;
      console.log(resp.body?.data);
    } else {
      console.log("went wroing", resp.status);
    }
  };

  getLapDetById = (pId) => {
    let index = this.lapsList.findIndex((prod) => prod._id == pId);
    if (index < 0) return "no prod here";
    return this.lapsList[index];
  };

  editLapsLocalStore = async (id, editedData) => {
    let dataToBeUpdate = this.getLapDetById(id);
    const indexTobeEdited = this.lapsList.indexOf(dataToBeUpdate);
    this.lapsList[indexTobeEdited] = { ...dataToBeUpdate, ...editedData };

    const updateStatus = await this.editLapsInDB(id, editedData);

    if (updateStatus !== 200) {
      this.lapsList[indexTobeEdited] = { ...dataToBeUpdate };
    }
  };

  getLapsByGameId = (gameId) => {
    let tempLaps = this.lapsList.filter((lap) => lap.gameId === gameId);
    this.currentLaps = tempLaps;
  };
}

export default ProductStore;
