import React from "react";
import Page from "src/components/Page";
import Container from "@mui/material/Container";
import { Icon } from "@iconify/react";
import submitIcon from "@iconify/icons-ant-design/send";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, TextField, Button } from "@mui/material";
import { Observer } from "mobx-react";
import { useStores } from "../state_management/store";

const spaceDiv = <div style={{ height: "10px" }} />;
export default function Notification() {
  const titleRef = React.useRef(null);
  const bodyRef = React.useRef(null);
  const { CommonStore } = useStores();
  return (
    <div>
      <Observer>
        {() => (
          <Page title="Notification">
            <Container maxWidth="xl">
              <Stack
                sx={{ pb: 5 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">Notifications</Typography>
              </Stack>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  CommonStore.sendNotificationByTopic(
                    titleRef.current.value,
                    bodyRef.current.value
                  );
                  titleRef.current.value = "";
                  bodyRef.current.value = "";
                }}
              >
                <TextField
                  id="title"
                  label="title"
                  fullWidth
                  required
                  inputRef={titleRef}
                />
                {spaceDiv}
                <TextField
                  id="description"
                  label="Description"
                  minRows="5"
                  multiline
                  required
                  fullWidth
                  inputRef={bodyRef}
                />
                {spaceDiv}

                <LoadingButton
                  type="submit"
                  loading={CommonStore.notificationLoader}
                  loadingPosition="start"
                  startIcon={<Icon icon={submitIcon} />}
                  variant="contained"
                >
                  Send
                </LoadingButton>
              </form>
            </Container>
          </Page>
        )}
      </Observer>
    </div>
  );
}
