import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress } from "@mui/material";
import { useStores } from "src/state_management/store";
import { Observer } from "mobx-react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BasicModal({
  modalOpen,
  title,
  onClose,
  children,
  width,
}) {
  const [open, setOpen] = React.useState(false);

  const { CommonStore, UserStore, ProductStore, OrdersStore, CouponStore } =
    useStores();

  const getLoader = () => {
    if (
      UserStore.loading ||
      ProductStore.loading ||
      OrdersStore.loading ||
      CommonStore.loading ||
      CouponStore.loading
    ) {
      return <LinearProgress />;
    } else {
      return <div></div>;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (modalOpen) {
      handleClickOpen();
    }
  }, []);

  return (
    <Observer>
      {() => (
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={width ?? "md"}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {title}
            </BootstrapDialogTitle>
            <DialogContent dividers>{children && children()}</DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
            {getLoader()}
          </BootstrapDialog>
        </div>
      )}
    </Observer>
  );
}
