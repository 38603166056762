import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { toJS } from "mobx";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Switch,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
// utils

//
import { useStores } from "../../../state_management/store/index";
import { useObserver } from "mobx-react";
//Component
import NewCouponModal from "src/reusable/new_coupon_modal";
import { MdEdit } from "react-icons/md";

// ---------------------------------------------------------------------

const CouponImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  // objectFit: 'cover',
  // chang
  objectFit: "contain",
  position: "absolute",
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// ----------------------------------------------------------------------

CouponCard.propTypes = {
  coupon: PropTypes.object,
};

export default function CouponCard({ coupon }) {

  const { code, media, label, startDate, endDate } = toJS(coupon);
  const [openModel, setOpenModel] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isAmount, setIsAmount] = useState(null);

  const id = coupon._id;
  console.log("coupon", media);
  const { CouponStore } = useStores();

  let couponFromStore = CouponStore.getProdDetById(id);
  let colors = [couponFromStore?.colorDetails?.primaryColor];
  let baseData = couponFromStore;
  const cover = media[0]?.url;

  const handleSwitchStateChange = (event, uId) => {
    CouponStore.editCouponLocalStore(uId, { isActive: event.target.checked });
    CouponStore.setActiveCoupon();
  };

  // Methods for more menu Option
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteCoupon = () => {
    CouponStore.deleteCoupon(id);
    handleClose();
  };

  return useObserver(() => (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <CouponImgStyle alt={code} src={cover} />
      </Box>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography
              variant="subtitle2"
              noWrap
              onClick={() => {
                setOpenModel(true);
                console.log(coupon);
              }}
            >
              {code}
            </Typography>
            <Typography variant="subtitle2" width={150} noWrap>
              {label}
            </Typography>
            <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
              {new Date(startDate).getDate() +
                "/" +
                (new Date(startDate).getMonth() + 1) +
                "/" +
                new Date(startDate).getFullYear()}{" "}
              -{" "}
              {new Date(endDate).getDate() +
                "/" +
                (new Date(endDate).getMonth() + 1) +
                "/" +
                new Date(endDate).getFullYear()}
            </Typography>
          </Link>
          <div>
            {/* More Menu for edit and delete Option */}
            <BsThreeDotsVertical
              id="demo-customized-button"
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                sx={{ display: "flex" }}
                onClick={() => {
                  handleClose();
                  setEditModal(true);
                  setIsAmount(true);
                }}
                disableRipple
              >
                <Button
                  color="inherit"
                  disableRipple
                  startIcon={<AiFillEdit />}
                  sx={{ fontWeight: 400, width: "100%" }}
                >
                  Edit
                </Button>
              </MenuItem>
              <MenuItem onClick={handleDeleteCoupon} disableRipple>
                <Button
                  color="inherit"
                  disableRipple
                  startIcon={<AiFillDelete />}
                  sx={{ fontWeight: 400, width: "100%" }}
                >
                  Delete
                </Button>
              </MenuItem>
            </StyledMenu>
          </div>
        </Stack>
        <Switch
          onChange={(e) => handleSwitchStateChange(e, id)}
          checked={couponFromStore?.isActive ?? false}
        />
      </Stack>
      <div>
        {editModal && (
          <NewCouponModal
            open={editModal}
            data={toJS(coupon)}
            onClose={() => {
              setEditModal(false);
            }}
            isAmount={isAmount}
            isEdit={true}
          />
        )}
      </div>
    </Card>
  ));
}
