import { makeAutoObservable } from "mobx";
import { apiGet, apiPostPut } from "../../resources/api_calls/api_methods";
import apiUrl from "../../resources/api_calls/api_urls";

class OrdersStore {
  ordersList = [];
  loading = false;
  constructor() {
    makeAutoObservable(this);
  }

  fetchOrdersFromBD = async (fetchMore, isRefresh) => {
    this.loading = true;
    const response = await apiGet(
      fetchMore === true
        ? apiUrl.listOrders + "?skip=" + this.ordersList.length + "&limit=10"
        : apiUrl.listOrders
    );
    this.loading = false;
    if (response.status === 200) {
      console.log(response.body?.data);

      this.ordersList =
        isRefresh === true
          ? response.body?.data
          : [...this.ordersList, ...response.body?.data];
    } else {
      console.log("went wroing", response.status);
      alert(`went wroing " + ${response.status}`);
    }
  };

  getRazorPayOrder = async (orderId) => {
    this.loading = true;
    const response = await apiGet(apiUrl.razorPayOrder + "/" + orderId);
    this.loading = false;
    if (response.status === 200) {
      return response.body?.data;
    } else {
      console.log("went wroing", response.status);
      alert(`went wroing " + ${response.status}`);
      return null;
    }
  };
  getOrderById = (id) => {
    let index = this.ordersList.findIndex((prod) => prod._id == id);
    if (index < 0) return "no prod here";
    return this.ordersList[index];
  };
  editOrderLocalStore = async (id, editedData) => {
    let dataToBeUpdate = this.getOrderById(id);
    const indexTobeEdited = this.ordersList.indexOf(dataToBeUpdate);
    this.ordersList[indexTobeEdited] = { ...dataToBeUpdate, ...editedData };
    // console.log(toJS(this.listProducts));

    const updateStatus = await this.editOrderInDB(id, editedData);

    if (updateStatus !== 200) {
      this.ordersList[indexTobeEdited] = { ...dataToBeUpdate };
    }
  };

  editOrderInDB = async (id, editedData) => {
    if (this.loading) {
      alert("loading Please wait..");
      return;
    }
    let body = {
      ...editedData,
    };
    let path = `/order/orders/${id}`;
    this.loading = true;
    const resp = await apiPostPut(body, path, "PUT");
    this.loading = false;

    return resp.status;
  };
}
export default OrdersStore;
