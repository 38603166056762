import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { useObserver } from "mobx-react";
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
//
import Scrollbar from "../../Scrollbar";
import ColorManyPicker from "../../ColorManyPicker";
import { useStores } from "../../../state_management/store/index";

// ----------------------------------------------------------------------

export const FILTER_USERS_OPTIONS = ["All", "Selected"];

// ----------------------------------------------------------------------

CouponFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

export default function CouponFilterSidebar({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  formik,
  setSelected,
  selected,
}) {
  const { values, getFieldProps, handleChange } = formik;

  // Method for active and delete category
  const { CouponStore } = useStores();

  const handleCheckedActivated1 = (boolValue) => {
    // Method for Displaying all the Activated Product
    setSelected(!boolValue);
  };
  const handleCheckedActivated2 = (boolValue) => {
    // Method for Displaying all the Activated Product
    setSelected(boolValue);
  };
  const handleCheckedDeleted = (e) => {
    // Method for Displaying Deleted Product
  };

  return useObserver(() => (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Icon icon={roundFilterList} />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: "none", overflow: "hidden" },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                Filters
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Users
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...getFieldProps("users")}
                          value="All"
                          checked={!selected}
                          onChange={handleCheckedActivated1}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...getFieldProps("users")}
                          value="Selected"
                          checked={selected}
                          onChange={handleCheckedActivated2}
                        />
                      }
                      label="Selected"
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  ));
}
