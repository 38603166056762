import React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

const SearchBar = ({ searchUser, userRef }) => {
  return (
    <form>
      <TextField
        id="search-bar"
        inputRef={userRef}
        label="Enter a user's name"
        variant="outlined"
        placeholder="Search..."
        size="small"
        sx={{ width: 330 }}
      />
      <IconButton onClick={searchUser} aria-label="search">
        <SearchIcon style={{ fill: "blue" }} />
      </IconButton>
    </form>
  );
};

export default SearchBar;
