import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { Switch } from "@mui/material";
import { Observer } from "mobx-react";
import { useStores } from "../state_management/store/index";
import { MdAdd } from "react-icons/md";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LapsModel(props) {
  const [open, setOpen] = React.useState(false);
  const [addLapsModal, setAddLapsModal] = React.useState(false);

  const { ProductStore } = useStores();

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (props.open) {
      handleClickOpen();
      ProductStore.getLapsByGameId(props.data._id);

      if (ProductStore.lapsList.length > 0) return;
      ProductStore.fetchLapsFromDB();
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    props?.onClose();
  };

  const handleStateChange = (event, id) => {
    ProductStore.editLapsLocalStore(id, { isActive: event.target.checked });
  };

  const addLapToGame = (id) => {
    ProductStore.editLapsLocalStore(id, { gameId: props.data._id });
  };

  return (
    <div>
      <Observer>
        {() => (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="lg"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Laps Details
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Button
                startIcon={<MdAdd />}
                onClick={() => setAddLapsModal(!addLapsModal)}
              >
                {!addLapsModal ? "Add new lap" : "Close"}
              </Button>
              {addLapsModal == false &&
                ProductStore?.lapsList
                  ?.filter((item) => item?.gameId === props?.data?._id)
                  .map((lap, key) => (
                    <>
                      <ImgMediaCard
                        key={key}
                        id={lap._id}
                        title={lap.name}
                        description={lap.description}
                        isActive={lap.isActive}
                        isActiveChange={handleStateChange}
                        price={lap.price}
                        type={lap.type}
                      />
                      <br></br>
                    </>
                  ))}
              <div>
                {addLapsModal == true &&
                  ProductStore?.lapsList
                    ?.filter(
                      (item) =>
                        item?.gameId === null ||
                        item?.gameId === "" ||
                        item?.gameId === undefined
                    )
                    .map((lap, key) => (
                      <>
                        <ImgMediaCard
                          key={key}
                          id={lap._id}
                          title={lap.name}
                          description={lap.description}
                          isActive={lap.isActive}
                          isActiveChange={handleStateChange}
                          price={lap.price}
                          type={lap.type}
                          addLap={addLapsModal}
                          addLapToGame={addLapToGame}
                        />
                        <br></br>
                      </>
                    ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>
        )}
      </Observer>
    </div>
  );
}

function ImgMediaCard({
  id,
  title,
  description,
  price,
  type,
  isActive,
  isActiveChange,
  addLap = false,
  addLapToGame,
}) {
  return (
    <Card>
      <CardHeader
        avatar={<Avatar aria-label="recipe">{title}</Avatar>}
        action={
          addLap === false ? (
            <Switch
              checked={isActive}
              onChange={(e) => isActiveChange(e, id)}
            />
          ) : (
            <Button startIcon={<MdAdd />} onClick={() => addLapToGame(id)}>
              Add this lap to game
            </Button>
          )
          // <IconButton aria-label="settings">
          //   <FavoriteIcon />
          // </IconButton>
        }
        title={title}
        subheader={
          description + `  price: ${price},    type: ${type},  _id: ${id}`
        }
      />
    </Card>
  );
}
