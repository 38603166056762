import React from "react";
import { useObserver } from "mobx-react";
import { useStores } from "src/state_management/store";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { toJS } from "mobx";

const SearchResult = ({ selectedUser, setSelectedUser }) => {
  const { CouponStore } = useStores();

  const pushUsers = (sUser) => {
    let duplicate = false;
    selectedUser.map((user) => {
      if (user._id === sUser._id) duplicate = true;
    });
    if (!duplicate) {
      // const tempUsers = [...users, id];
      // users = tempUsers;
      setSelectedUser([...selectedUser, toJS(sUser)]);
    }
    console.log(selectedUser);
  };

  return useObserver(() => (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
      }}
    >
      {CouponStore.listUsers.map((result, key) => (
        <>
          <ListItem
            alignItems="flex-start"
            style={{ cursor: "pointer" }}
            key={key}
            onClick={() => pushUsers(result)}
          >
            <ListItemText
              primary={result.name}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {result.mobile}
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {result.eMail}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="middle" component="li" />
        </>
      ))}
    </List>
  ));
};

export default SearchResult;
