import { makeAutoObservable } from "mobx";
import { addHeader, addHeaderWithOutBody } from "src/resources/headers/header";
import api_urls from "../../resources/api_calls/api_urls";
import constants from "../../resources/api_calls/api_urls";

class CommonStore {
  isUserAuthenticated = false;
  adminUser = {};
  loading = false;
  dashBoardDetails = {};
  notificationLoader = false;

  constructor() {
    makeAutoObservable(this);
  }
  login() {
    this.isUserAuthenticated = true;
  }
  logout() {
    this.isUserAuthenticated = false;
  }
  setAdmin(adminUser) {
    this.adminUser = adminUser;
  }

  async fetchAdminUserFromDB(uId) {
    const body = {
      uId: uId,
    };
    const res = await this.apiPostPut(body, api_urls.login, "POST");
    if (res.status === 200) {
      this.adminUser = res?.body?.data?.user;
      console.log("user login details", res?.body?.data?.user);
    } else {
      alert("something went wrong with admin user");
    }
  }

  async sendNotificationByTopic(title, body) {
    const payload = {
      topic: "gokart",
      title: title,
      body: body,
    };
    this.notificationLoader = true;
    const res = await this.apiPostPut(
      payload,
      api_urls.notificationByTopic,
      "POST"
    );
    this.notificationLoader = false;
    if (res.status === 200) {
      console.log("notification sent");
      alert("notification sent successfully");
    } else {
      alert("something went wrong with notification");
    }
  }

  async fetchDashBoardDetails(isRefresh) {
    if (!isRefresh) {
      if (Object.keys(this.dashBoardDetails).length > 0) return;
    }
    const res = await this.apiGet(api_urls.dashboard_details);
    if (res.status === 200) {
      this.dashBoardDetails = res.body;
    }
  }

  async apiPostPut(body, path, method) {
    this.loading = true;
    const uri =
      (constants.isLocalServer ? constants.localUrl : constants.baseUrl) + path;
    const response = await fetch(uri, await addHeader(body, method));
    try {
      const data = await response.json();
      this.loading = false;
      return { body: data, status: response.status };
    } catch (error) {
      this.loading = false;
      return { status: response.status };
    }
  }

  async apiGet(path) {
    this.loading = true;
    const response = await fetch(
      (constants.isLocalServer ? constants.localUrl : constants.baseUrl) + path,
      await addHeaderWithOutBody("GET")
    );
    console.log(response);

    const data = await response.json();
    this.loading = false;
    return { body: data, status: response.status };
  }

  async apiDelete(path) {
    this.loading = true;
    const response = await fetch(
      (constants.isLocalServer ? constants.localUrl : constants.baseUrl) + path,
      await addHeaderWithOutBody("DELETE")
    );
    console.log(response);
    const data = response.status === 200 ? response.json() : {};
    this.loading = false;
    return { body: data, status: response.status };
  }
}
export default CommonStore;
