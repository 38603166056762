import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import ShopProductCard from "./ProductCard";
import { Observer } from "mobx-react";

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  return (
    <Observer>
      {() => (
        <Grid container spacing={3} {...other}>
          {products.map((product, key) => (
            <Grid key={key} item xs={12} sm={6} md={3}>
              <div>
                <ShopProductCard product={product} />
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </Observer>
  );
}
