import React, { useEffect } from "react";
import BasicModal from "./basic_modal";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/lab";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { useStores } from "src/state_management/store";
import { uploadFileToFirebaseStorage } from "src/utils/common";
import SearchBar from "src/components/SearchBar";
import SearchResult from "src/components/SearchResult";

export default function NewCouponModal(props) {
  const { CommonStore, CouponStore } = useStores();
  const [isEdit, setIsEdit] = React.useState(props?.isEdit ?? false);
  const [isAmount, setIsAmount] = React.useState(
    isEdit ? props?.data?.discountType : ""
  );

  const [discountType, setdiscountType] = React.useState(props?.data?.discountType ?? null);

  const [isPerson, setIsPerson] = React.useState(
    isEdit ? (props?.data?.selectedUser.length > 0 ? "selected" : "all") : ""
  );
  const [pickedImage, setPickedImage] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(
    props?.data?.selectedUser ?? []
  );

  //create a ref to store the textInput DOM element
  const nameRef = React.useRef(null);
  const typeRef = React.useRef(null);
  const labelRef = React.useRef(null);
  const discountAmountRef = React.useRef(null);
  const orderAboveRef = React.useRef(null);
  const maxDiscountRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const userRef = React.useRef("");

  const dummyImage =
    "https://toppng.com/uploads/preview/file-upload-image-icon-115632290507ftgixivqp.png";

  const handleChange = (event) => {
    setIsAmount(event.target.value);
    setdiscountType(event.target.value);
  };

  useEffect(() => {
    console.log(selectedUser);
  }, [selectedUser]);

  const handleChange1 = (event) => {
    setIsPerson(event.target.value);
  };

  const getSelectedUserFromList = (list) => {
    let selectedUser = [];
    list.forEach((item) => {
      selectedUser.push(item._id);
    });
    return selectedUser;
  };

  const submitAmountCoupon = async (e) => {
    e?.preventDefault();

    if (pickedImage === null && !isEdit) {
      alert("Please upload an image");
      return;
    }
    CouponStore.setLoading(true);
    let image =
      pickedImage == null
        ? [props?.data?.media[0]?.url]
        : await uploadFileToFirebaseStorage([pickedImage]);
    CouponStore.setLoading(false);
    console.log(image);

    const body = {
      code: nameRef.current.value,
      discountType: typeRef.current.value,
      discount: discountAmountRef.current.value,
      orderAbove: orderAboveRef.current.value,
      maxDiscount: maxDiscountRef.current.value,
      startDate:
        Number(startDate) === 0 ? props?.data?.startDate : Number(startDate),
      endDate: Number(endDate) === 0 ? props?.data?.endDate : Number(endDate),
      label: labelRef.current.value,
      description: descriptionRef.current.value,
      createdBy: CommonStore?.adminUser?._id,
      media: [
        {
          url: image[0],
          type: "image",
        },
      ],
      selectedUser: getSelectedUserFromList(selectedUser),
      // selectedUser: isEdit
      //   ? selectedUser.length > 0
      //     ? [...props?.data?.selectedUser, selectedUser]
      //     : props?.data?.selectedUser
      //   : selectedUser,
    };

    if (isEdit) {
      CouponStore.editCouponLocalStore(props?.data?._id, body);
      alert("Coupon updated successfully");
      props?.onClose();
    } else {
      if ((await CouponStore.createNewCoupon(body)) === true) {
        alert("Coupon created successfully");
        props?.onClose();
      }
    }
    CouponStore.listUsers = [];
    CouponStore.fetchCouponFromDB();
  };
  // console.log(selectedUser);
  //   console.log(props.data.selectedUser);

  const submitPercentCoupon = async (e) => {
    e?.preventDefault();

    if (pickedImage === null && !isEdit) {
      alert("Please upload an image");
      return;
    }
    CouponStore.setLoading(true);
    let image =
      pickedImage == null
        ? [props?.data?.media[0]?.url]
        : await uploadFileToFirebaseStorage([pickedImage]);
    CouponStore.setLoading(false);
    console.log(image);

    const body = {
      code: nameRef.current.value,
      discountType: typeRef.current.value,
      discount: discountAmountRef.current.value,
      orderAbove: orderAboveRef.current.value,
      maxDiscount: maxDiscountRef.current.value,
      startDate:
        Number(startDate) === 0 ? props?.data?.startDate : Number(startDate),
      endDate: Number(endDate) === 0 ? props?.data?.endDate : Number(endDate),
      label: labelRef.current.value,
      description: descriptionRef.current.value,
      createdBy: CommonStore?.adminUser?._id,
      media: [
        {
          url: image[0],
          type: "image",
        },
      ],
      selectedUser: isEdit
        ? selectedUser.length > 0
          ? [...props?.data?.selectedUser, selectedUser]
          : props?.data?.selectedUser
        : selectedUser,
    };

    if (isEdit) {
      CouponStore.editCouponLocalStore(props?.data?._id, body);
      alert("Coupon updated successfully");
      props?.onClose();
    } else {
      if ((await CouponStore.createNewCoupon(body)) === true) {
        alert("Coupon created successfully");
        props?.onClose();
      }
    }
    CouponStore.listUsers = [];
    CouponStore.fetchCouponFromDB();
  };

  const getImgage = () => {
    if (isEdit === true && pickedImage === null) {
      return props?.data?.media[0]?.url ?? dummyImage;
    } else {
      return pickedImage == null
        ? dummyImage
        : URL.createObjectURL(pickedImage);
    }
  };

  const searchUser = async (e) => {
    e?.preventDefault();
    if ((await CouponStore.searchForUser(userRef)) === true) {
      return;
    }
  };

  const emptyUser = () => {
    CouponStore.listUsers = [];
  };

  const couponAmountForm = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={submitAmountCoupon}>
          <Box sx={{ marginTop: 2 }}>
            {isPerson === "selected" ? (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <Stack direction="row" spacing={1}>
                  {selectedUser.map((item, key) => (
                    <Chip
                      label={item.name}
                      onDelete={() => {
                        setSelectedUser(
                          selectedUser.filter((user) => user?._id !== item?._id)
                        );
                      }}
                      key={key}
                    />
                  ))}
                </Stack>
                <SearchBar userRef={userRef} searchUser={searchUser} />
                <SearchResult
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              </div>
            ) : null}
            <div style={{ display: "flex", marginBottom: "20px" }}>
              {props?.data?.selectedUser.length > 0 && (
                <h4 style={{ margin: "0 5px 0 5px" }}>Selected Users:</h4>
              )}
              {props?.data?.selectedUser.length > 0 &&
                props?.data?.selectedUser.map((user) => (
                  <p style={{ margin: "0 2px" }}>
                    {user ===
                    props?.data?.selectedUser[
                      props?.data?.selectedUser.length - 1
                    ]
                      ? user.name
                      : user.name + ","}
                  </p>
                ))}
            </div>

            <TextField
              id="code"
              label="Coupon Code Name"
              inputRef={nameRef}
              required
              defaultValue={isEdit ? props?.data?.code : ""}
            />
            <TextField
              id="discountType"
              label="Discount Type"
              inputRef={typeRef}
              type="hidden"
              value={discountType}
            />

            <TextField
              id="discount"
              label="Discount amount"
              inputRef={discountAmountRef}
              required
              type="number"
              defaultValue={isEdit ? props?.data?.discount : ""}
            />
            <TextField
              id="orderAbove"
              label="Minimum amount to avail discount"
              inputRef={orderAboveRef}
              type="number"
              required
              defaultValue={isEdit ? props?.data?.orderAbove : ""}
            />
            <TextField
              id="maxDiscount"
              label="Maximum discount amount"
              inputRef={maxDiscountRef}
              type="number"
              required
              defaultValue={isEdit ? props?.data?.maxDiscount : ""}
            />
            <TextField
              id="label"
              label="Label"
              inputRef={labelRef}
              required
              defaultValue={isEdit ? props?.data?.label : ""}
            />
            <TextField
              id="description"
              label="Description"
              inputRef={descriptionRef}
              minRows="3"
              multiline
              required
              defaultValue={isEdit ? props?.data?.description : ""}
            />
            <DateTimePicker
              label={
                isEdit
                  ? new Date(props?.data?.startDate).getDate() +
                    "/" +
                    (new Date(props?.data?.startDate).getMonth() + 1) +
                    "/" +
                    new Date(props?.data?.startDate).getFullYear() +
                    " " +
                    (new Date(props?.data?.startDate).getHours() % 12
                      ? new Date(props?.data?.startDate).getHours() % 12
                      : 12) +
                    ":" +
                    (new Date(props?.data?.startDate).getMinutes() < 10
                      ? "0" + new Date(props?.data?.startDate).getMinutes()
                      : new Date(props?.data?.startDate).getMinutes()) +
                    " " +
                    (new Date(props?.data?.startDate).getHours() >= 12
                      ? "pm"
                      : "am")
                  : "Start Date"
              }
              renderInput={(params) => <TextField {...params} />}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
            <DateTimePicker
              label={
                isEdit
                  ? new Date(props?.data?.endDate).getDate() +
                    "/" +
                    (new Date(props?.data?.endDate).getMonth() + 1) +
                    "/" +
                    new Date(props?.data?.endDate).getFullYear() +
                    " " +
                    (new Date(props?.data?.endDate).getHours() % 12
                      ? new Date(props?.data?.endDate).getHours() % 12
                      : 12) +
                    ":" +
                    (new Date(props?.data?.endDate).getMinutes() < 10
                      ? "0" + new Date(props?.data?.endDate).getMinutes()
                      : new Date(props?.data?.endDate).getMinutes()) +
                    " " +
                    (new Date(props?.data?.endDate).getHours() >= 12
                      ? "pm"
                      : "am")
                  : "End Date"
              }
              renderInput={(params) => <TextField {...params} />}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
            <input
              type="file"
              id="file"
              hidden
              onChange={(e) => {
                setPickedImage(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
            />
            <img
              className="pointer"
              onClick={() => {
                document.getElementById("file").click();
              }}
              src={getImgage()}
              alt="coupon"
            />
          </Box>
          {/* submit button */}
          <Button type="submit">{isEdit ? "Update" : "Create"}</Button>
        </form>
      </LocalizationProvider>
    );
  };

  const couponPercentForm = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={submitPercentCoupon}>
          <Box sx={{ marginTop: 2 }}>
            {isPerson === "selected" ? (
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 20,
                }}
              >
                <SearchBar userRef={userRef} searchUser={searchUser} />
                <SearchResult
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              </div>
            ) : null}
            <div style={{ display: "flex", marginBottom: "20px" }}>
              {props?.data?.selectedUser.length > 0 && (
                <h4 style={{ margin: "0 5px 0 5px" }}>Selected Users:</h4>
              )}
              {props?.data?.selectedUser.length > 0 &&
                props?.data?.selectedUser.map((user) => (
                  <p style={{ margin: "0 2px" }}>
                    {user ===
                    props?.data?.selectedUser[
                      props?.data?.selectedUser.length - 1
                    ]
                      ? user.name
                      : user.name + ","}
                  </p>
                ))}
            </div>
            <TextField
              id="code"
              label="Coupon code name"
              inputRef={nameRef}
              required
              defaultValue={isEdit ? props?.data?.code : ""}
            />
            <TextField
              id="discountType"
              label="Discount Type"
              inputRef={typeRef}
              type="hidden"
              value={discountType}
            />

            <TextField
              id="discount"
              label="Discount %"
              inputRef={discountAmountRef}
              required
              type="number"
              defaultValue={isEdit ? props?.data?.discount : ""}
            />
            <TextField
              id="orderAbove"
              label="Minimum amount to avail discount"
              inputRef={orderAboveRef}
              required
              type="number"
              defaultValue={isEdit ? props?.data?.orderAbove : ""}
            />
            <TextField
              id="maxDiscount"
              label="Maximum discount"
              inputRef={maxDiscountRef}
              required
              type="number"
              defaultValue={isEdit ? props?.data?.maxDiscount : ""}
            />
            <TextField
              id="label"
              label="Label"
              inputRef={labelRef}
              required
              defaultValue={isEdit ? props?.data?.label : ""}
            />
            <TextField
              id="description"
              label="Description"
              inputRef={descriptionRef}
              minRows="3"
              multiline
              required
              defaultValue={isEdit ? props?.data?.description : ""}
            />
            <DateTimePicker
              label={
                isEdit
                  ? new Date(props?.data?.startDate).getDate() +
                    "/" +
                    (new Date(props?.data?.startDate).getMonth() + 1) +
                    "/" +
                    new Date(props?.data?.startDate).getFullYear() +
                    " " +
                    (new Date(props?.data?.startDate).getHours() % 12
                      ? new Date(props?.data?.startDate).getHours() % 12
                      : 12) +
                    ":" +
                    (new Date(props?.data?.startDate).getMinutes() < 10
                      ? "0" + new Date(props?.data?.startDate).getMinutes()
                      : new Date(props?.data?.startDate).getMinutes()) +
                    " " +
                    (new Date(props?.data?.startDate).getHours() >= 12
                      ? "pm"
                      : "am")
                  : "Start Date"
              }
              renderInput={(params) => <TextField {...params} />}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
            <DateTimePicker
              label={
                isEdit
                  ? new Date(props?.data?.endDate).getDate() +
                    "/" +
                    (new Date(props?.data?.endDate).getMonth() + 1) +
                    "/" +
                    new Date(props?.data?.endDate).getFullYear() +
                    " " +
                    (new Date(props?.data?.endDate).getHours() % 12
                      ? new Date(props?.data?.endDate).getHours() % 12
                      : 12) +
                    ":" +
                    (new Date(props?.data?.endDate).getMinutes() < 10
                      ? "0" + new Date(props?.data?.endDate).getMinutes()
                      : new Date(props?.data?.endDate).getMinutes()) +
                    " " +
                    (new Date(props?.data?.endDate).getHours() >= 12
                      ? "pm"
                      : "am")
                  : "End Date"
              }
              renderInput={(params) => <TextField {...params} />}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
            <input
              type="file"
              id="file"
              hidden
              onChange={(e) => {
                setPickedImage(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
            />
            <img
              className="pointer"
              onClick={() => {
                document.getElementById("file").click();
              }}
              src={getImgage()}
              alt="coupon"
            />
          </Box>
          {/* submit button */}
          <Button type="submit">{isEdit ? "Update" : "Create"}</Button>
        </form>
      </LocalizationProvider>
    );
  };
  // React.useEffect(()=> {
  //   CouponStore.fetchCouponFromDB();
  // },[submitAmountCoupon, submitPercentCoupon])
  const mainComponent = () => {
    return (
      <div>
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Please select discount type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isAmount}
              label="Please select"
              onChange={handleChange}
            >
              <MenuItem value={"amount"}>Amount</MenuItem>
              <MenuItem value={"percentage"}>Percentage</MenuItem>
              <MenuItem value={"orderPercentage"}>order Percentage</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Please select coupon type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isPerson}
              label="Please select"
              onChange={handleChange1}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"selected"}>Selected Person</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {isAmount === "amount" ? couponAmountForm() : couponPercentForm()}
      </div>
    );
  };

  return (
    <div>
      <BasicModal
        title={"new coupon"}
        modalOpen={props.open}
        onClose={() => {
          props.onClose();
          emptyUser();
        }}
        children={mainComponent}
      />
    </div>
  );
}
