import moment from "moment";
// import "firebase";

import Firebase from "../firebase";
import "firebase/compat/storage";

export const format = ["DD/MM/YY  ddd  hh:mm a", "DD-MMM-YY", "ddd  hh:mm a"];

export const dateFormat = (value, format) => {
  return moment(value).format(format);
};
export const timeFormat = (value, format) => {
  return moment(value).CalendarKey(format);
};

// export const Truth = {
//   YES: "Yes",
//   NO: "No",
// };

// export const Status = {
//   ACTIVE: "actiive",
//   BAN: "ban",
//   INACTIVE: "inactive",
// };
const storage = Firebase.storage();
//upload files to firebase storage
export const uploadFileToFirebaseStorage = async (files) => {
  const storageRef = storage.ref();
  let downloadlinks = [];

  // promise method
  return new Promise((resolve, reject) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const uploadTask = storageRef.child(`images/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            downloadlinks.push(downloadURL);
            // return downloadURL;
            if (downloadlinks.length == files?.length) {
              if (i == files.length - 1) {
                console.log("done");
                resolve(downloadlinks);
              } else {
                console.log("not done");
              }
            }
          });
        }
      );
    }
  });
};
